<template>
  <div>
    <div class="pc card-container unselect" @click="clickDetail(card)">
      <div class="card-img" :style="imgField(card.img, 'pc')"></div>
      <div class="card-content">
        <img style="width:32px;height:32px"
             :src="card.partner.img" v-if="card.partner.img">
        <div>
          <div class="subtitle7 main">{{ card.name }}</div>
          <!-- 가격 정보 -->
          <!--<div v-if="discountedPrice(card)>0">
            &lt;!&ndash;<div class="body5 sub4 text-line-through" v-if="isDiscount(card)">{{ card.price.price | currency }}</div>&ndash;&gt;
            <div class="flex-align">
              <div class="margin-right-4 primary body4-bold" v-if="card.price.discount_type===0">{{ discountText(card) }}</div>
              <div class="body4-bold">
                <span class="sub">{{ discountedPrice(card) | currencyNum }}</span>
                <span class="body4 sub2">원</span>
                <span class="body4 sub3" v-if="card.price.is_subscription">/월</span>
                <span class="body4 sub2 margin-left-4" v-if="card.category2!==42">~</span>
              </div>
            </div>
          </div>-->
        </div>
      </div>
    </div>
    <div class="mobile card-container" @click="clickDetail(card)">
      <div class="card-img" :style="imgField(card.img, 'mobile')"></div>
      <div class="card-content">
        <img style="width:18px;height:18px"
             :src="card.partner.img" v-if="card.partner.img">
        <div>
          <div class="body6 main">{{ card.name }}</div>
          <!-- 가격 정보 -->
          <!--<div v-if="discountedPrice(card)>0">
            &lt;!&ndash;<div class="body5 sub4 text-line-through" v-if="isDiscount(card)">{{ card.price.price | currency }}</div>&ndash;&gt;
            <div class="flex-align">
              <div class="margin-right-4 primary body6-bold" v-if="card.price.discount_type===0">{{ discountText(card) }}</div>
              <div class="body6-bold">
                <span class="sub">{{ discountedPrice(card) | currencyNum }}</span>
                <span class="body6 sub2">원</span>
                <span class="body6 sub3" v-if="card.price.is_subscription">/월</span>
                <span class="body6 sub2 margin-left-4" v-if="card.category2!==42">~</span>
              </div>
            </div>
          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "CardProductSimple",
    props: {
      card: {
        type: Object
      },
    },
    methods: {
      discountText(product) {
        let text = '';
        if (this.isDiscount(product)) {
          if (product.price.discount_rate > 0 && product.price.discount_type === 0) {
            text = product.price.discount_rate + '%'
          } else if (product.price.discount_price !== 0 && product.price.discount_type === 1) {
            text = product.price.discount_price + '원';
          }
        }
        return text;
      },
      // 할인 여부
      isDiscount(product) {
        if (product.price.is_discount && product.price.discount_start !== null && product.price.discount_end !== null) {
          let currentDate = this.dayjs().format('YYYY-MM-DD HH:mm');
          return !(currentDate < product.price.discount_start || currentDate > product.price.discount_end);
        } else if (product.price.discount_start !== null && product.price.discount_end === null) {
          let currentDate = this.dayjs().format('YYYY-MM-DD HH:mm');
          return currentDate >= product.price.discount_start;
        } else if (product.price.discount_start === null && product.price.discount_end !== null) {
          let currentDate = this.dayjs().format('YYYY-MM-DD HH:mm');
          return currentDate <= product.price.discount_end;
        } else {
          return false;
        }
      },
      // 상품 가격
      discountedPrice(product) {
        let total = 0;
        if (this.isDiscount(product)) {
          if (product.price.discount_rate > 0 && product.price.discount_type === 0) {
            total = product.price.price * (1 - (product.price.discount_rate / 100))
          } else if (product.price.discount_price !== 0 && product.price.discount_type === 1) {
            total = product.price.price - product.price.discount_price;
          }
        } else total = product.price.price;
        return total;
      },
      // 상세 페이지 이동
      clickDetail(card) {
        this.routeGa('',`부가서비스 카드(${card.name})`, card.name);
        this.$router.push(`/additional_service_detail?id=${card.id}`)
      },
      imgField(img, device) {
        let style = {
          backgroundImage: `url(${this.image(img)})`,
          backgroundSize: 'cover'
        };
        if(device==='pc') {
          style.backgroundPosition = 'center';
        } else {
          style.backgroundPosition = 'top';
        }
        return style;
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .pc
    .card-container
      padding 12px
      background-color #ffffff
      border-radius 8px
    .card-img
      position relative
      width 100%
      height 246px
      border-radius 8px
      background-color $gray4
      border 1px solid $gray3

    .card-content
      margin-top 10px
      display flex
      align-items center
      gap 8px

  .mobile
    .card-container
      padding 8px
      background-color #ffffff
      border-radius 8px
    .card-img
      position relative
      width 100%
      height 149px
      border-radius 8px
      background-color $gray4
      border 1px solid $gray3

    .card-content
      margin-top 10px
      display flex
      align-items center
      gap 8px
      text-align left
</style>
